import React from "react";

const Arrow = (props) => (

        <button onClick={props.onClick}  className="invisible">
            <svg width="33px" height="18px" viewBox="0 0 33 18" version="1.1" >
                <g id="Website" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="3-copy-7" transform="translate(-145.000000, -825.000000)" className="arrowSvg" strokeWidth="2">
                        <polyline id="Path-3" transform="translate(161.500000, 833.268744) rotate(-270.000000) translate(-161.500000, -833.268744) " points="154.063932 818.268744 168.936068 833.50665 154.063932 848.268744"></polyline>
                    </g>
                </g>
            </svg>
        </button>
);

export default Arrow;
