import Layout from "../components/Layout.js";
import React, { Component } from "react";
import { Link } from "gatsby";

import SEO from "../components/seo.js";
import Wheel from "../components/Wheel.js";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Arrow from "../components/Arrow.js";

import VideoItem from "../components/VideoItem.js";

import { Element ,  scroller } from 'react-scroll'


class Index extends Component {
    

    constructor()
    {
        super();

        this.state = {
            currentPageIndex : '0',
            exit : false,
            isChromium : false
        };
    }


    onMenuHover = (event) => {

        this.setState({ currentPageIndex : parseInt(event.target.id) });

    }

    onArrowClick = (event) => {
      console.log("ARrow");
      scroller.scrollTo('arrowDestination', {
        duration: 500,
        delay: 0,
        smooth: true
      })
    }

    componentDidMount()
    {
      this.setState({isChromium : !!window.chrome});
      
    }

    /*
    interestingExitAnimation = (exit, node) => {
      console.log("EXIT");
      this.setState({ exit: true });
    }
    */

    render() {
        const acfData = this.props.data.allWordpressPage.edges[0].node.acf;
        const keywords = acfData.keywords ? acfData.keywords.split(",").map(item => item.trim()) : [];
        const thisComponent = this;
        const introBgStyle ={ backgroundImage: `url("${acfData.intro_bg_image.localFile.childImageSharp.fixed.src}")` };

        return (
              <Layout white={false} exit={this.state.exit}>
              <SEO title="Home" description={acfData.description} keywords={keywords} ogImageUrl={acfData.og_img.source_url} />

                <div id="homeFrontPage" className={this.state.isChromium ? 'chrome' : ''}>
                    <div id="homeWheelSectionContainer">
                      <div id="homeWheelSection">

                          <div id="ThomeWheelSectionLeft">
                            
                          </div>

                          <div id="homeWheelSectionMiddle">
                              <div className="menu menuMiddleContainer">
                                  <ul> 
                                    <li> 
                                        
                                        <Link id="0" to="/organisatie" onMouseEnter={thisComponent.onMenuHover} className="categoryFontSize">
                                        Organisatie
                                        </Link>
                                        <br/>
                                        <span>{acfData.organisatie_ondertitel}</span>
                                    </li>

                                    <li> 
                                        
                                        <Link id="1" to="/onderwijs" onMouseEnter={thisComponent.onMenuHover} className="categoryFontSize">
                                          Onderwijs
                                        </Link>
                                        <br/>
                                        <span>{acfData.onderwijs_ondertitel}</span>
                                    </li>

                                    <li> 
                                      
                                        <Link id="2" to="/ontwikkeling" onMouseEnter={thisComponent.onMenuHover} className="categoryFontSize">
                                          Ontwikkeling
                                        </Link>
                                        <br/>
                                        <span>{acfData.ontwikkeling_ondertitel}</span>
                                    </li>

                                  </ul>
                              </div>

                              
                        </div>

                        <div id="homeWheelSectionRight">
                            <Wheel 

                              img1={acfData.organisatie_img.localFile.childImageSharp.fluid} 
                              img2={acfData.onderwijs_img.localFile.childImageSharp.fluid} 
                              img3={acfData.ontwikkeling_img.localFile.childImageSharp.fluid} 

                              currentPageIndex={this.state.currentPageIndex}
                            />
                        </div>

                      </div>

                    </div>
                    <div id="arrowContainer">
                      <Arrow onClick={this.onArrowClick} />
                    </div>
                  </div>
                  <Element name="arrowDestination">  </Element>
                  <div id="intro" style={introBgStyle}>
                 
                   
                    <div className="streamer contentMargin"  dangerouslySetInnerHTML={{ __html: acfData.intro }}>
                  
                    </div>
                  
                    { acfData.intro_youtube_id ?
                    <div id="introVideoContainer">
                    
                    <VideoItem 

                          fluid={acfData.intro_video_image.localFile.childImageSharp.fluid} 
                          fixed={true}
                          videoId={acfData.intro_youtube_id} 

                          />
                  
                    </div>
                    : null }
                  </div>

                  <div id="introFooter" className="verticalAlignContainer">


                        <div className="verticalAlignChild">
                          <div id="introFooterLogos">

                            <a href="https://www.onbegrensdezaken.nl" target="_blank" rel="noopener noreferrer">
                              <Img className="imageColumn" fixed={this.props.data.ozImage.childImageSharp.fixed} />
                            </a>
                            <a href="https://www.stir.nu" target="_blank" rel="noopener noreferrer">
                              <Img className="imageColumn" fixed={this.props.data.stirImage.childImageSharp.fixed} />
                            </a>
                          </div>

                          <div id="introFooterText">
                            Lid van de coöperatie Onbegrensde Zaken<br/>
                            en het St!R keurmerk voor professioneel begeleiden.
                          </div>

                      </div>

                  </div>
              </Layout>
        );
    }
}
export default () => (
  <StaticQuery
    query={graphql`
      query {

          stirImage: file(relativePath: { eq: "stir.png" }) {
            childImageSharp {
              fixed(width:75) {
                ...GatsbyImageSharpFixed
              }
            }
          }

          ozImage: file(relativePath: { eq: "oz.png" }) {
            childImageSharp {
              fixed (width:75) {
                ...GatsbyImageSharpFixed
              }
            }
          }

          allWordpressPage(filter: {slug: {eq: "home"}}) {
            edges {
              
              node {
                
                title
                content
                excerpt
                date
                modified
                slug 
                status

                acf
                {
                  keywords,
                  description,
                  og_img
                  {
                    source_url
                  },

                  intro
                  intro_bg_image {
                    localFile
                    {
                      childImageSharp
                      {
                        fixed(quality:100, height:500)
                        {
                          
                          ...GatsbyImageSharpFixed_withWebp_noBase64
                        }
                      }
                    }
                  }
                  intro_youtube_id

                  intro_video_image {
                    localFile
                    {
                      childImageSharp
                      {
                        fluid
                        {
                          
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                  }

                  organisatie_img {
                    localFile
                    {
                      childImageSharp
                      {
                        fluid
                        {
                          
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                  }
                  ontwikkeling_img {
                    localFile
                    {
                      childImageSharp
                      {
                        fluid
                        {
                          
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                  }
                  onderwijs_img {
                    localFile
                    {
                      childImageSharp
                      {
                        fluid
                        {
                          
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                  }


                  onderwijs_ondertitel
                  ontwikkeling_ondertitel
                  organisatie_ondertitel

                }
              }
            }
          }
        
      }
    `}
    render={data => <Index data={data} />}
  />
)