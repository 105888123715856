import React, { Component } from "react";
import Img from "gatsby-image"

class Wheel extends Component {
    constructor(props) {
        super(props);


        this.state = { currentPageIndex : props.currentPageIndex };

        this.mobileIntervalHandle = null;
        this.resizeTimeout = null;
    }


    componentDidMount()
    {
        window.addEventListener("resize", this.onResize);
        window.addEventListener("orientationchange", this.onResize);

        this.onResize();
    }

    componentWillReceiveProps(nextProps)
    {
        if (nextProps.currentPageIndex !== this.state.currentPageIndex)
            this.setState({ currentPageIndex : nextProps.currentPageIndex });
    }


    componentWillUnmount()
    {
        window.removeEventListener("resize", this.onResize);
        window.removeEventListener("orientationchange", this.onResize);

        if (this.mobileIntervalHandle)
        {
            clearInterval(this.mobileIntervalHandle);
            this.mobileIntervalHandle = null;
        }
    }

    onResize = () => {  
        if (this.resizeTimeout)
        {
            clearTimeout(this.resizeTimeout);
        }

        // Do hacky with a timeout because #$#$*& on mobile after orientation change

        this.resizeTimeout = setTimeout(() => {
        if (typeof window !== 'undefined' && window)
        {
            if (parseInt(window.innerWidth) <= 800)
            {
                if (!this.mobileIntervalHandle)
                    this.mobileIntervalHandle = setInterval(this.mobileInterval, 3000);
            }
            else
            {
                if (this.mobileIntervalHandle)
                {
                    clearInterval(this.mobileIntervalHandle);
                    this.mobileIntervalHandle = null;
                   
                }

                this.setState({ currentPageIndex : 0 });
            }

            this.resizeTimeout = null;
        }
        }, 100);
    }

    mobileInterval = () => {
        this.setState({ currentPageIndex : (this.state.currentPageIndex + 1 % 3)});
    }

    render() {

        const { img1, img2, img3 } = this.props;
        const { currentPageIndex } = this.state;
 
        let imgClass = ['','',''];
        let j = currentPageIndex;
        for (var i = 0; i < 3; i++)
        {
            switch (i)
            {
                case 0:
                imgClass[j] = 'wheelImageContainer270 '; 
                break;
                case 1:
                imgClass[j] = 'wheelImageContainer45 '; 
                break;
                case 2:
                imgClass[j] = 'wheelImageContainer180 '; 
                break;
                default:
                imgClass[j] = '';
                break;
            }
            j = (j + 1) % 3;
        }

        return (
            <div id="wheelContainer">
                <div  id="wheelSquare">

                        <div className={"wheelImageContainer second " + imgClass[1]}>
                            <div className="wheelImageCrop">
                            <Img className="wheelImage" fluid={img2} critical={true} />
                            </div>
                        </div>

                        <div className={"wheelImageContainer first " + imgClass[0]}>
                            <div className="wheelImageCrop">
                               <Img className="wheelImage" fluid={img1} critical={true} />
                            </div>
                        </div>

                        <div className={"wheelImageContainer third " + imgClass[2]}>
                            <div className="wheelImageCrop">
                                <Img className="wheelImage" fluid={img3} critical={true} />
                            </div>
                        </div>

                </div>
            </div>
        );
    }
}

export default Wheel;
